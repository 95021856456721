import * as React from 'react';
import classNames from 'classnames';
import useMergedState from 'rc-util/lib/hooks/useMergedState';
import Radio from './radio';
import { ConfigContext } from '../config-provider';
import SizeContext from '../config-provider/SizeContext';
import { RadioGroupContextProvider } from './context';
const RadioGroup = React.forwardRef((props, ref) => {
    const { getPrefixCls, direction } = React.useContext(ConfigContext);
    const size = React.useContext(SizeContext);
    const [value, setValue] = useMergedState(props.defaultValue, {
        value: props.value,
    });
    const onRadioChange = (ev) => {
        const lastValue = value;
        const val = ev.target.value;
        if (!('value' in props)) {
            setValue(val);
        }
        const { onChange } = props;
        if (onChange && val !== lastValue) {
            onChange(ev);
        }
    };
    const renderGroup = () => {
        const { prefixCls: customizePrefixCls, className = '', options, optionType, buttonStyle, disabled, children, size: customizeSize, style, id, onMouseEnter, onMouseLeave, } = props;
        const prefixCls = getPrefixCls('radio', customizePrefixCls);
        const groupPrefixCls = `${prefixCls}-group`;
        let childrenToRender = children;
        // 如果存在 options, 优先使用
        if (options && options.length > 0) {
            const optionsPrefixCls = optionType === 'button' ? `${prefixCls}-button` : prefixCls;
            childrenToRender = options.map(option => {
                if (typeof option === 'string') {
                    // 此处类型自动推导为 string
                    return (<Radio ref={ref} key={option} prefixCls={optionsPrefixCls} disabled={disabled} value={option} checked={value === option}>
              {option}
            </Radio>);
                }
                // 此处类型自动推导为 { label: string value: string }
                return (<Radio ref={ref} key={`radio-group-value-options-${option.value}`} prefixCls={optionsPrefixCls} disabled={option.disabled || disabled} value={option.value} checked={value === option.value} style={option.style}>
            {option.label}
          </Radio>);
            });
        }
        const mergedSize = customizeSize || size;
        const classString = classNames(groupPrefixCls, `${groupPrefixCls}-${buttonStyle}`, {
            [`${groupPrefixCls}-${mergedSize}`]: mergedSize,
            [`${groupPrefixCls}-rtl`]: direction === 'rtl',
        }, className);
        return (<div className={classString} style={style} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} id={id}>
        {childrenToRender}
      </div>);
    };
    return (<RadioGroupContextProvider value={{
        onChange: onRadioChange,
        value,
        disabled: props.disabled,
        name: props.name,
    }}>
      {renderGroup()}
    </RadioGroupContextProvider>);
});
RadioGroup.defaultProps = {
    buttonStyle: 'outline',
};
export default React.memo(RadioGroup);
