import * as React from 'react';
import classNames from 'classnames';
function notEmpty(val) {
    return val !== undefined && val !== null;
}
const Cell = ({ itemPrefixCls, component, span, className, style, bordered, label, content, colon, }) => {
    const Component = component;
    if (bordered) {
        return (<Component className={classNames({
            [`${itemPrefixCls}-item-label`]: notEmpty(label),
            [`${itemPrefixCls}-item-content`]: notEmpty(content),
        }, className)} style={style} colSpan={span}>
        {notEmpty(label) ? label : content}
      </Component>);
    }
    return (<Component className={classNames(`${itemPrefixCls}-item`, className)} style={style} colSpan={span}>
      <div className={`${itemPrefixCls}-item-container`}>
        {label && (<span className={classNames(`${itemPrefixCls}-item-label`, {
        [`${itemPrefixCls}-item-no-colon`]: !colon,
    })}>
            {label}
          </span>)}
        {content && <span className={classNames(`${itemPrefixCls}-item-content`)}>{content}</span>}
      </div>
    </Component>);
};
export default Cell;
