var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as React from 'react';
import CloseOutlined from '@ant-design/icons/CloseOutlined';
import CheckCircleOutlined from '@ant-design/icons/CheckCircleOutlined';
import ExclamationCircleOutlined from '@ant-design/icons/ExclamationCircleOutlined';
import InfoCircleOutlined from '@ant-design/icons/InfoCircleOutlined';
import CloseCircleOutlined from '@ant-design/icons/CloseCircleOutlined';
import CheckCircleFilled from '@ant-design/icons/CheckCircleFilled';
import ExclamationCircleFilled from '@ant-design/icons/ExclamationCircleFilled';
import InfoCircleFilled from '@ant-design/icons/InfoCircleFilled';
import CloseCircleFilled from '@ant-design/icons/CloseCircleFilled';
import CSSMotion from 'rc-motion';
import classNames from 'classnames';
import { ConfigContext } from '../config-provider';
import getDataOrAriaProps from '../_util/getDataOrAriaProps';
import ErrorBoundary from './ErrorBoundary';
import { replaceElement } from '../_util/reactNode';
const iconMapFilled = {
    success: CheckCircleFilled,
    info: InfoCircleFilled,
    error: CloseCircleFilled,
    warning: ExclamationCircleFilled,
};
const iconMapOutlined = {
    success: CheckCircleOutlined,
    info: InfoCircleOutlined,
    error: CloseCircleOutlined,
    warning: ExclamationCircleOutlined,
};
const Alert = (_a) => {
    var { description, prefixCls: customizePrefixCls, message, banner, className = '', style, onMouseEnter, onMouseLeave, onClick, afterClose, showIcon, closable, closeText } = _a, props = __rest(_a, ["description", "prefixCls", "message", "banner", "className", "style", "onMouseEnter", "onMouseLeave", "onClick", "afterClose", "showIcon", "closable", "closeText"]);
    const [closed, setClosed] = React.useState(false);
    const ref = React.useRef();
    const { getPrefixCls, direction } = React.useContext(ConfigContext);
    const prefixCls = getPrefixCls('alert', customizePrefixCls);
    const handleClose = (e) => {
        var _a;
        setClosed(true);
        (_a = props.onClose) === null || _a === void 0 ? void 0 : _a.call(props, e);
    };
    const getType = () => {
        const { type } = props;
        if (type !== undefined) {
            return type;
        }
        // banner 模式默认为警告
        return banner ? 'warning' : 'info';
    };
    // closeable when closeText is assigned
    const isClosable = closeText ? true : closable;
    const type = getType();
    const renderIconNode = () => {
        const { icon } = props;
        // use outline icon in alert with description
        const iconType = (description ? iconMapOutlined : iconMapFilled)[type] || null;
        if (icon) {
            return replaceElement(icon, <span className={`${prefixCls}-icon`}>{icon}</span>, () => ({
                className: classNames(`${prefixCls}-icon`, {
                    [icon.props.className]: icon.props.className,
                }),
            }));
        }
        return React.createElement(iconType, { className: `${prefixCls}-icon` });
    };
    const renderCloseIcon = () => {
        return isClosable ? (<button type="button" onClick={handleClose} className={`${prefixCls}-close-icon`} tabIndex={0}>
        {closeText ? (<span className={`${prefixCls}-close-text`}>{closeText}</span>) : (<CloseOutlined />)}
      </button>) : null;
    };
    // banner 模式默认有 Icon
    const isShowIcon = banner && showIcon === undefined ? true : showIcon;
    const alertCls = classNames(prefixCls, `${prefixCls}-${type}`, {
        [`${prefixCls}-with-description`]: !!description,
        [`${prefixCls}-no-icon`]: !isShowIcon,
        [`${prefixCls}-banner`]: !!banner,
        [`${prefixCls}-closable`]: isClosable,
        [`${prefixCls}-rtl`]: direction === 'rtl',
    }, className);
    const dataOrAriaProps = getDataOrAriaProps(props);
    return (<CSSMotion visible={!closed} motionName={`${prefixCls}-motion`} motionAppear={false} motionEnter={false} onLeaveStart={node => ({
        maxHeight: node.offsetHeight,
    })} onLeaveEnd={afterClose}>
      {({ className: motionClassName, style: motionStyle }) => (<div ref={ref} data-show={!closed} className={classNames(alertCls, motionClassName)} style={Object.assign(Object.assign({}, style), motionStyle)} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} onClick={onClick} role="alert" {...dataOrAriaProps}>
          {isShowIcon ? renderIconNode() : null}
          <span className={`${prefixCls}-message`}>{message}</span>
          <span className={`${prefixCls}-description`}>{description}</span>
          {renderCloseIcon()}
        </div>)}
    </CSSMotion>);
};
Alert.ErrorBoundary = ErrorBoundary;
export default Alert;
