import * as React from 'react';
import usePatchElement from '../../_util/hooks/usePatchElement';
import HookModal from './HookModal';
import { withConfirm, withInfo, withSuccess, withError, withWarn, } from '../confirm';
let uuid = 0;
export default function useModal() {
    const [elements, patchElement] = usePatchElement();
    function getConfirmFunc(withFunc) {
        return function hookConfirm(config) {
            uuid += 1;
            const modalRef = React.createRef();
            let closeFunc;
            const modal = (<HookModal key={`modal-${uuid}`} config={withFunc(config)} ref={modalRef} afterClose={() => {
                closeFunc();
            }}/>);
            closeFunc = patchElement(modal);
            return {
                destroy: () => {
                    if (modalRef.current) {
                        modalRef.current.destroy();
                    }
                },
                update: (newConfig) => {
                    if (modalRef.current) {
                        modalRef.current.update(newConfig);
                    }
                },
            };
        };
    }
    return [
        {
            info: getConfirmFunc(withInfo),
            success: getConfirmFunc(withSuccess),
            error: getConfirmFunc(withError),
            warning: getConfirmFunc(withWarn),
            confirm: getConfirmFunc(withConfirm),
        },
        <>{elements}</>,
    ];
}
