import * as React from 'react';
import CloseCircleFilled from '@ant-design/icons/CloseCircleFilled';
import SearchOutlined from '@ant-design/icons/SearchOutlined';
import Input from '../input';
export default class Search extends React.Component {
    constructor() {
        super(...arguments);
        this.handleChange = (e) => {
            const { onChange } = this.props;
            if (onChange) {
                onChange(e);
            }
        };
        this.handleClear = (e) => {
            e.preventDefault();
            const { handleClear, disabled } = this.props;
            if (!disabled && handleClear) {
                handleClear(e);
            }
        };
    }
    render() {
        const { placeholder, value, prefixCls, disabled } = this.props;
        const icon = value && value.length > 0 ? (<a className={`${prefixCls}-action`} onClick={this.handleClear}>
          <CloseCircleFilled />
        </a>) : (<span className={`${prefixCls}-action`}>
          <SearchOutlined />
        </span>);
        return (<>
        <Input placeholder={placeholder} className={prefixCls} value={value} onChange={this.handleChange} disabled={disabled}/>
        {icon}
      </>);
    }
}
Search.defaultProps = {
    placeholder: '',
};
