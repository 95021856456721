var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as React from 'react';
import RcPagination from 'rc-pagination';
import enUS from 'rc-pagination/lib/locale/en_US';
import classNames from 'classnames';
import LeftOutlined from '@ant-design/icons/LeftOutlined';
import RightOutlined from '@ant-design/icons/RightOutlined';
import DoubleLeftOutlined from '@ant-design/icons/DoubleLeftOutlined';
import DoubleRightOutlined from '@ant-design/icons/DoubleRightOutlined';
import MiniSelect from './MiniSelect';
import Select from '../select';
import LocaleReceiver from '../locale-provider/LocaleReceiver';
import { ConfigContext } from '../config-provider';
import useBreakpoint from '../grid/hooks/useBreakpoint';
const Pagination = (_a) => {
    var { prefixCls: customizePrefixCls, selectPrefixCls: customizeSelectPrefixCls, className, size, locale: customLocale } = _a, restProps = __rest(_a, ["prefixCls", "selectPrefixCls", "className", "size", "locale"]);
    const { xs } = useBreakpoint();
    const { getPrefixCls, direction } = React.useContext(ConfigContext);
    const prefixCls = getPrefixCls('pagination', customizePrefixCls);
    const getIconsProps = () => {
        const ellipsis = <span className={`${prefixCls}-item-ellipsis`}>•••</span>;
        let prevIcon = (<button className={`${prefixCls}-item-link`} type="button" tabIndex={-1}>
        <LeftOutlined />
      </button>);
        let nextIcon = (<button className={`${prefixCls}-item-link`} type="button" tabIndex={-1}>
        <RightOutlined />
      </button>);
        let jumpPrevIcon = (<a className={`${prefixCls}-item-link`}>
        
        <div className={`${prefixCls}-item-container`}>
          <DoubleLeftOutlined className={`${prefixCls}-item-link-icon`}/>
          {ellipsis}
        </div>
      </a>);
        let jumpNextIcon = (<a className={`${prefixCls}-item-link`}>
        
        <div className={`${prefixCls}-item-container`}>
          <DoubleRightOutlined className={`${prefixCls}-item-link-icon`}/>
          {ellipsis}
        </div>
      </a>);
        // change arrows direction in right-to-left direction
        if (direction === 'rtl') {
            [prevIcon, nextIcon] = [nextIcon, prevIcon];
            [jumpPrevIcon, jumpNextIcon] = [jumpNextIcon, jumpPrevIcon];
        }
        return {
            prevIcon,
            nextIcon,
            jumpPrevIcon,
            jumpNextIcon,
        };
    };
    const renderPagination = (contextLocale) => {
        const locale = Object.assign(Object.assign({}, contextLocale), customLocale);
        const isSmall = size === 'small' || !!(xs && !size && restProps.responsive);
        const selectPrefixCls = getPrefixCls('select', customizeSelectPrefixCls);
        const extendedClassName = classNames(className, {
            mini: isSmall,
            [`${prefixCls}-rtl`]: direction === 'rtl',
        });
        return (<RcPagination {...restProps} prefixCls={prefixCls} selectPrefixCls={selectPrefixCls} {...getIconsProps()} className={extendedClassName} selectComponentClass={isSmall ? MiniSelect : Select} locale={locale}/>);
    };
    return (<LocaleReceiver componentName="Pagination" defaultLocale={enUS}>
      {renderPagination}
    </LocaleReceiver>);
};
export default Pagination;
